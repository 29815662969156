<template>
  <div class="home-about" @click="$router.push('/about')">
    <h4>{{ FullYear }}年品牌值得信赖</h4>
    <p>打造中国一流的智力文化企业</p>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
  data () {
    return {
      Date: new Date(),
      createFullYear: 1995
    };
  },
  computed: {
    FullYear () {
      return this.Date.getFullYear() - this.createFullYear;
    }
  }
};
</script>
<style lang="less" scoped>
  .home-about {
    width: 100%;
    height: 2.18rem;
    background: url('@/assets/img/home/home-about.png') no-repeat center center;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 0.32rem;
      font-weight: 500;
      color: #FFF;
      line-height: 0.46rem;
      margin-bottom: 0.12rem;
    }
    p {
      font-size: 0.24rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.35rem;
      letter-spacing: 0.02rem;
    }

  }
</style>