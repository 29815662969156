import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/index.vue';
import routerList from '@/assets/js/router';
import util from '@/assets/js/util';

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '首页',
      jumpPcPath: ({params, query, data}) => {
        return '/';
      }
    }
  },
  ...routerList,
  {
    path: '/promotion/:qd',
    name: 'channel',
    component: () => import('@/views/channel/index.vue'),
    meta: {
      title: '渠道推广',
    }
  },
  {
    path: '/sale-code',
    name: 'SaleCode',
    component: () => import('@/views/sale-code/index.vue'),
    meta: {
      title: '专属销售',
    }
  },
  {
    path: '/customer-center',
    name: 'CustomerCenter',
    component: () => import('@/views/uc/customer-center/index.vue'),
    meta: {
      title: '专属销售',
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404/index.vue'),
    meta: {
      title: '404',
      jumpPcPath: ({params, query, data}) => {
        return '/404';
      }
    }
  },
  {
    path: '*',
    redirect: '/404',
  }
  // {
  //   path: '*',
  //   redirect: '/404',
  // }
];

const router = new VueRouter({
  // base: '/new/',
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {y: 0};
  },
});

let loginPath = '/uc/login';
router.beforeEach((to, form, next) => {
  // if (to.path === form.path) return;
  // 不需要登录的路由直接放行
  document.title = to.meta.title || '天一网校';
  // document.title = '天一网校';
  if (!to.meta.permission && to.path !== loginPath) {
    return next();
  }
  var userToken = localStorage.getItem('user_token') || '';
  // 登录之后不允许用户再次回到登录页,从哪来回哪去
  // if (userToken && to.path === loginPath) {
  //   document.title = form.meta.title || '天一网校';
  //   return next(form.path);
  // };
  // 拥有登录信息的用户访问权限页面放行
  if (userToken && to.meta.permission) {
    return next();
  // 没有登录信息的用户访问权限页面跳转登录页面
  } else {
    if (to.path === loginPath) {
      return next();
    }
    document.title = '天一网校';
    // return next(loginPath);
    // return next(`${loginPath}?url=${encodeURIComponent(location.origin + '/new' + to.path)}`);
    if (util.isWeixinBrowser()) {
      return next();
    }
    return next(`${loginPath}?url=${encodeURIComponent(location.href)}`);
  }

});
export default router;
