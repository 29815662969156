<template>
  <van-popup v-model="show"
             closeable
             transition-appear
             :close-on-click-overlay="false"
             safe-area-inset-bottom
             get-container="body"
             :close-icon="require('@/assets/img/home/ad_close.png')"
             :style="{
               overflowY: 'initial',
               background: 'none'
             }">
    <div class="content-box">
      <van-swipe v-if="adList.length > 1" class="swipe-content" indicator-color="white" :autoplay="3000">
        <van-swipe-item v-for="item in adList" :key="item.id" @click="toPath(item)">
          <img v-lazy="item.adContent" :src="item.adContent" class="swipe-img" />
        </van-swipe-item>
      </van-swipe>
      <img v-else class="swipe-img" :src="adList.length ? adList[0]['adContent'] : adData.imgUrl" @click="adList.length ? toPath(adList[0]) : () =>{}">
    </div>
    <div class="popup-day-hide">
      <van-checkbox v-model="popupDayHide" class="radio-icon" />今日不再出现
    </div>
  </van-popup>
</template>

<script>
import homeApi from '@/api/home/index';
import util from '@/assets/js/util';
export default {
  name: 'AdDialog',
  data () {
    return {
      adData: {},
      show: false,
      popupDayHide: false,
      loginAdCode: 'H5-HOME-MASK'
    };
  },
  computed: {
    adList () {
      return this.adData.adItemDtoList || [];
    },
  },
  watch: {
    show () {
      if(!this.show && this.popupDayHide) {
        const eTime = new Date(new Date().setHours(23, 59, 59, 0)).getTime();
        const cTime = new Date().getTime();
        const oneDay = 24 * 60 * 60 * 1000;
        const expDay = (eTime - cTime) / oneDay;
        util.setCookie('adtime', true, expDay);
      }
    }
  },
  methods: {
    getAdList () {
      try {
        homeApi.getAdList(this.loginAdCode).then(res => {
          this.adData = res.data.data;
          const adshow = this.adData.id && ((this.adData.adItemDtoList && this.adData.adItemDtoList.length) || this.adData.imgUrl);
          if (adshow && !Boolean(util.getCookie('adtime'))) {this.show = true;}
        });
      } catch(error) {
        console.log(error);
      }
    },
    toPath (item) {
      if(item.linkUrl) {
        sessionStorage.setItem('openPath', encodeURIComponent(location.href));
        window.open(item.linkUrl, item.blank ? '_blank' : '_self');
      }
    }
  }
};
</script>
<style lang="less" scoped>
  ::v-deep .van-popup {
    overflow-y: inherit !important;
  }
  ::v-deep .van-popup__close-icon {
    top: -0.8rem;
    right: 0;
    .van-icon__image {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  .content-box {
    position: relative;
    width: 4.2rem;
    height: 5rem;
    border-radius: 0.2rem;
    overflow: hidden;
    // background: #fff;
    .swipe-content {
      width: 100%;
      height: 100%;
    }
    .swipe-img {
      width: 100%;
      height: 100%;
    }
  }
  .popup-day-hide {
    margin-top: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.22rem;
    color: #fff;
    .radio-icon {
      position: relative;
      width: 0.27rem;
      height: 0.27rem;
      border-radius: 50%;
      margin-right: 15px;
      ::v-deep .van-icon,::v-deep .van-checkbox__icon {
        width: 100%;
        height: 100%;
        font-size: 0.23rem;
      }
    }
  }
</style>