import course from './course';
import ucRouter from './uc';
import order from './order';
import active from './active';
import transfer from './transfer';
import wechatPublicAccountQrCode from './wechatPublicAccountQrCode';


const routerList = [...course, ...ucRouter, ...order, ...active, ...transfer, ...wechatPublicAccountQrCode];

export default routerList;
