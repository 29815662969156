import request from '../request';

// 绑定意向项目
function bindUserProject (data) {
  return request({
    url: '/usercenter/front/api/account/bindProject',
    method: 'PUT',
    data,
  });
}

export default {
  bindUserProject
};
