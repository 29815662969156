<template>
  <div v-if="courseList.length" class="module-course-free">
    <div v-for="item in courseList" :key="item.id" class="course-item-card">
      <div class="item-card-content" @click="toCourseDetail(item)">
        <div class="course-img">
          <!-- <img v-if="item.coverImages" :src="item.coverImages" width="100%" height="100%" /> -->
          <span class="course-hour">{{ item.totalHour }}课时</span>
        </div>
        <div class="course-info">
          <h3 class="course-title">{{ item.name }}</h3>
          <div class="course-price">{{ item.type === 'FREE' ? '免费' : ''}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from '@/api/home/index';
export default {
  name: 'HomeCourseFree',
  props: {
    fetchCallback: {
      type: Function,
      default: () =>([])
    }
  },
  created () {
    this.fetchFreeList();
  },
  data () {
    return {
      courseLoading: false,
      courseList: []
    };
  },
  methods: {
    fetchFreeList () {
      try {
        this.courseLoading = true;
        homeApi.getCourseList({
          categoryId: '',
          orderFiled: '',
          page: 1,
          size: 2,
          projectId: '',
          subjectId: '',
          types: ['FREE']
        }).then(res=> {
          if(res.data.code === 200) {
            const data = res.data.data;
            this.courseList = data.rows || [];
            this.fetchCallback(data.rows);
          }
        }).finally(() => {
          this.courseLoading = false;
        });        
      } catch (error) {
        
      }
    },
    toCourseDetail (item) {
      if(item.id) {
        this.$router.push(`/course/detail/${item.id}`);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.module-course-free {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.6rem;
  .course-item-card {
    width: 49%;
    height: 3.71rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(106,152,184,0.1);
    border-radius: 0.08rem;
    .item-card-content {
      padding: 0.16rem;
      .course-img {
        position: relative;
        width: 100%;
        height: 2.14rem;
        background: url('@/assets/img/home/free-img.png') no-repeat;
        background-size: 100% 2.14rem;
        border-radius: 5px;
        overflow: hidden;
        .course-hour {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0.83rem;
          height: 0.4rem;
          line-height: 0.4rem;
          background: rgba(12,12,12,0.3);
          border-radius: 0px 5px 0px 5px;
          font-size: 0.2rem;
          font-weight: 400;
          color: #FFF;
          text-align: center;
        }
      }
      .course-info {
        padding-top: 0.16rem;
        font-size: 0.28rem;
        font-weight: 500;
        line-height: 0.41rem;
        .course-title {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          margin-bottom: 0.16rem;
          color: #1D2035;
        }
        .course-price {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          margin-bottom: 0.16rem;
          color: #EA0000;
          font-weight: bold;
        }

      }

    }
  }

}
</style>