<template>
  <div v-if="resourceList.length" class="module-course-resource">
    <div class="resource-content">
      <div v-for="item in resourceList" :key="item.id" class="resource-item" @click="downloadFile(item)">
        <div class="resource-item-content">
          <div class="resource-img"> <img v-if="fileList[item.format]" :src="fileList[item.format]" width="100%" height="100%" /> </div>
          <div class="resource-info">
            <h3 class="resource-title">{{ item.name }}</h3>
            <div class="resource-line">
              <span class="size">{{ item.resourceSize | fileSizeFilter }}</span>
              <span class="create-time">{{ item.createdOn }}</span>
            </div>
          </div>
        </div>
        <img class="resource-download" src="@/assets/img/home/resource-download.png" />
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from '@/api/home/index';
import { mapGetters, mapActions } from 'vuex';
import util from '@/assets/js/util';

export default {
  name: 'HomeCourseResource',
  props: {
    fetchCallback: {
      type: Function,
      default: () =>([])
    }
  },
  filters: {
    fileSizeFilter (data) {
      if (data <= 1024 ) {
        return data + 'KB';
      } if (data >= 1024) {
        return (data / 1024).toFixed(2) + 'MB';
      }
      return '0KB';
    }
  },
  data () {
    return {
      resourceLoading: false,
      resourceList: [],
      fileList: {
        pdf: require('@/assets/img/home/file_pdf.png'),
        word: require('@/assets/img/home/file_word.png'),
        word: require('@/assets/img/home/file_word.png'),
        xsl: require('@/assets/img/home/file_xsl.png'),
        zip: require('@/assets/img/home/file_zip.png'),
      }
    };
  },
  computed: {
    ...mapGetters('user', ['isLogin']),
  },
  created () {
    this.fetchResourceList();
  },
  methods: {
    ...mapActions({
      'wxLogin': 'common/wxLogin'
    }),
    fetchResourceList () {
      try {
        this.resourceLoading = true;
        homeApi.getProductFreeData({
          categoryId: '',
          projectId: '',
          page: 1,
          size: 4
        }).then(res=> {
          if(res.data.code === 200) {
            const data = res.data.data;
            this.resourceList = data.rows || [];
            this.fetchCallback(this.resourceList);
          }
        }).finally(() => {
          this.resourceLoading = false;
        });        
      } catch (error) {
        
      }
    },
    downloadFile (item) {
      if (this.isLogin) {
        window.open(item.resourceUrl, '_blank');
      } else {
        if (util.isWeixinBrowser()) {
          this.wxLogin({ route: this.$route, snsapiType: true });
        } else {
          let path = `/uc/login?url=${encodeURIComponent(location.href)}`;
          this.$router.push(path);
        }
      }
    },
  }
};
</script>

<style lang="less" scoped>
.module-course-resource {
  padding-bottom: 0.6rem;
  .resource-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(106,152,184,0.1);
    border-radius: 0.08rem;
    padding: 0.27rem 0.24rem;
  }
  .resource-item {
    position: relative;
    width: 100%;
    height: 0.9rem;
    margin-bottom: 0.27rem;
    padding-right: 0.6rem;
    box-sizing: border-box;
    .resource-item-content {
      display: flex;
      .resource-img {
        width: 0.9rem;
        height: 0.9rem;
        background: url('@/assets/img/home/file_default.png') no-repeat;
        background-size: 100% 0.9rem;
        margin-right: 0.24rem;
      }
      .resource-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0.08rem;
        width: calc(100% - 1.14rem);
        .resource-title {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          font-size: 0.28rem;
          font-weight: 400;
          color: #1D2035;
          line-height: 0.46rem;
        }
        .resource-line {
          width: 100%;
          display: flex;
          align-items: center;
          span {
            font-size: 0.24rem;
            font-weight: 400;
            color: #9FA0A8;
            line-height: 0.18rem;
            padding-right: 0.1rem;
            height: 0.18rem;
          }
          span + span {
            padding-left: 0.1rem;
            border-left: 1px solid #D8D8D8;;
          }
        }

      }
    }
    .resource-download {
      width: 0.36rem;
      height: 0.36rem;
      position: absolute;
      right: 0.1rem;
      top: 50%;
      transform: translateY(-0.18rem);
    }
  }

}
</style>