<template>
  <div v-if="teacherList.length" class="home-teacher-list">
    <div class="teacher-content-box">
      <img src="@/assets/img/home/teacher_title.png" class="teacher-title">
      <div class="teacher-swipe">
        <van-swipe class="my-swipe" loop :show-indicators="false" :autoplay="3000">
          <van-swipe-item v-for="item in teacherList" :key="item.id">
            <div class="teacher-item">
              <div class="teacher-info">
                <div class="teacher-name">{{ item.name }}</div>
                <ul class="teacher-tag">
                  <li v-for="(tag, index) in tagList(item.projectNames)" :key="index" class="tag-item">{{ tag }}</li>
                </ul>
                <div class="teacher-introduce">{{ item.introduce | filterLength }}</div>
              </div>
              <div class="teacher-avatar">
                <img :src="item.avatar" class="teacher-img" />
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TeacherList',
  props: {
    teacherList: {
      required: true,
      type: Array,
    },
  },
  filters: {
    filterLength (text = '') {
      return text.length > 74 ? (text.slice(0, 74) + '...') : text;
    }
  },
  methods: {
    tagList (tagstr = '') {
      const arr = tagstr.split('、');
      return arr.length > 4 ? arr.slice(0, 4) : arr;
    }
  }

};
</script>

<style lang="less" scoped>
.home-teacher-list {
  position: relative;
  width: 100%;
  height: 4.74rem;
  padding-bottom: 0.6rem;
  background-color: #fff;
  .teacher-content-box {
    width: 100%;
    height: 4.27rem;
    background: url('@/assets/img/home/teacher_bg.png') no-repeat center top;
    background-size: 100% 4.27rem;
    box-sizing: border-box;
    .teacher-title {
      display: block;
      width: 3.95rem;
      height: 0.58rem;
      padding: 0.32rem 0;
      margin: 0 auto;
    }
    .teacher-swipe {
      width: 100%;
      padding: 0 0.32rem;
      box-sizing: border-box;
      .my-swipe {
        box-sizing: border-box;
        width: 100%;
        border-radius: 0.1rem;
        overflow: hidden;
      }
    }
    .teacher-item {
      width: 100%;
      height: 3.52rem;
      background: #fff url('@/assets/img/home/teacher_contentbg.png') no-repeat right bottom;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .teacher-info {
        margin-top: 0.32rem;
        margin-left: 0.32rem;
        width: 50%;
        overflow: hidden;
        .teacher-name {
          position: relative;
          font-size: 0.32rem;
          font-weight: bold;
          color: #1D2035;
          line-height: 0.35rem;
          margin-bottom: 0.15rem;
          padding-left: 0.15rem;
          display: flex;
          align-items: center;
          &::before {
            content: ' ';
            width: 0.05rem;
            height: 0.28rem;
            background: #2080F7;
            border-radius: 0.02rem;
            display: block;
            position: absolute;
            left: 0;
          }
        }
        .teacher-tag {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.16rem;
          max-height: 0.92rem;
          overflow: hidden;
          .tag-item {
            font-size: 0.24rem;
            font-weight: 400;
            color: #aa8361;
            height: 0.36rem;
            line-height: 0.36rem;
            background: linear-gradient(90deg, #FAEADB 0%, #F8CA9E 100%);
            border-radius: 0.10rem;
            margin-right: 0.1rem;
            margin-bottom: 0.1rem;
            padding: 0.02rem 0.15rem;
          }
        }
        .teacher-introduce {
          font-size: 0.20rem;
          font-weight: 400;
          color: #596B81;
          line-height: 0.29rem;
          max-height: 1.6rem;
          min-height: 1.2rem;
        }
      }
      .teacher-avatar {
        width: 2.44rem;
        // height: 3.52rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .teacher-img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .my-swipe {
    width: 100%;

  }

}
</style>