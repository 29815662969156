const transferRouter = [
  {
    path: '/transfer',
    name: 'TransferHome',
    component: () => import('@/views/transfer/home.vue'),
    children: [
      {
        path: '',
        name: 'TransferPage',
        component: () => import('@/views/transfer/index.vue')
      },
      {
        path: 'open-mini-app/:urlkey',
        name: 'OpenMiniApp',
        component: () => import('@/views/transfer/open-mini-app.vue'),
      },
      {
        path: 'sl/:sessionid',
        name: 'ShortLink',
        component: () => import('@/views/transfer/short-link.vue'),
      },
      {
        path: '/transfer.html',
        name: 'transferHtml',
        component: () => import('@/views/transfer/transfer-html.vue'),
      },
    ]
  }
];

export default transferRouter;