import axios from 'axios';
// import qs from 'qs';
import router from '@/router/index';
import store from '@/main';
import { Toast } from 'vant';
import util from '@/assets/js/util';
const showStatus = (status) => {
  let message = '';
  switch (status) {
  case 400:
    message = '请求错误(400)';
    break;
  case 401:
    message = '请重新登录(401)';
    break;
  case 403:
    message = '拒绝访问(403)';
    break;
  case 404:
    message = '请求出错(404)';
    break;
  case 408:
    message = '请求超时(408)';
    break;
  case 500:
    message = '服务器错误(500)';
    break;
  case 501:
    message = '服务未实现(501)';
    break;
  case 502:
    message = '网络错误(502)';
    break;
  case 503:
    message = '服务不可用(503)';
    break;
  case 504:
    message = '网络超时(504)';
    break;
  case 505:
    message = 'HTTP版本不受支持(505)';
    break;
  default:
    message = `连接出错(${status})!`;
  }
  return `${message}，请检查网络或稍后再试`;
};

// axios.defaults.withCredentials = true;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Source-Type'] = '';
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 8000
});

service.interceptors.request.use(
  config => {
    if (config.setContentTypeHeader) {
      config.headers['Content-Type'] = config.setContentTypeHeader;
    }
    config.headers.Authorization = store.state.user.token ? `Bearer ${store.state.user.token}` : '';
    config.headers['Terminal-Type'] = 'H5';
    if (!config.noSourceType) {
      const source = window.localStorage.getItem('source');
      config.headers['Source-Type'] = source || '';
    }
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    if (response.config.responseType === 'arraybuffer' || response.data.code === 200) {
      return response;
    }
    var msg = response.data.msg || showStatus(response.data.code);
    Toast(msg);
    return Promise.reject(response);
  },
  error => {
    console.log('err' + error); // for debug
    // console.log(error.response)
    if(!error.response) {
      var msg = showStatus(error.code);
      Toast(msg);
      return Promise.reject(error);
    }
    var msg = error.response.data.msg || showStatus(error.response.data.status);
    Toast(msg);
    if (error.response.data.code === 401) {
      store.commit('user/clearUserInfo');
      if(util.isWeixinBrowser() || (router.history && router.history.current && util.filterLogin(router.history.current))) {
        // store.dispatch('common/wxLogin', router.history.current);
        router.push('/uc/index');
      } else {
        // router.push('/uc/login');
        var url = location.href;
        router.push(`/uc/login?url=${encodeURIComponent(url)}`);
      }
      // return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
function http (config) {
  if (config.method.toUpperCase() === 'GET') {
    config.params = config.data;
  }
  return service(config);
}

export default http;
