import request from '../request';


// 获取首页轮播图
export function getAdList (code, classId) {
  return request({
    url: `/content/front/api/adItem/getAdItemListByClassId/${code}${classId ? `/${classId}` : ''}`,
    method: 'get'
  });
}
// 获取课程
function getCourseList (data) {
  return request({
    url: '/products/front/api/course/getPageList',
    method: 'POST',
    data,
  });
}
// 获取免费资料
export function getProductFreeData (data) {
  return request({
    url: '/products/front/api/resource/getfreepagelist',
    method: 'post',
    data
  });
}

// 获取讲师列表
export function getTeacherList (data) {
  return request({
    url: '/products/front/api/courseteacher/getPageList',
    method: 'post',
    data
  });
}


export default {
  getAdList,
  getCourseList,
  getProductFreeData,
  getTeacherList,
};
