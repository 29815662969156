import { create, all } from 'mathjs';
import VConsole from 'vconsole';
import VueClipBoard from 'vue-clipboard2';
import util from './assets/js/util';
import newStore from './store';
const store = newStore();
export default store;
// mixins
import userLogin from '@/mixins/userLogin';
import jumpPc from '@/mixins/jumpPc';
import setChannelCode from '@/mixins/set-channel-code';
import JsBridge from '@/assets/js/js-bridge.js'; //  bridge file path
Vue.prototype.$bridge = JsBridge;

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/reset.css';

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
Vue.use(VueAwesomeSwiper);

import {
  Icon,
  Button,
  Field,
  Form,
  Checkbox,
  Toast,
  CheckboxGroup,
  Dialog,
  Popup,
  Cascader,
  Switch,
  Empty,
  Tab,
  Tabs,
  Uploader,
  List,
  PullRefresh,
  Collapse,
  CollapseItem,
  Loading,
  Sticky,
  ActionSheet,
  Overlay,
  Swipe,
  SwipeItem,
  Lazyload,
  RadioGroup,
  Radio,
  NoticeBar
} from 'vant';

Toast.allowMultiple();
Toast.setDefaultOptions({
  className: 'vant-toast-custom',
});
Vue.use(Icon);
Vue.use(Button);
Vue.use(Field);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Cascader);
Vue.use(Switch);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Loading);
Vue.use(Sticky);
Vue.use(ActionSheet);
Vue.use(Overlay);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(NoticeBar);


Vue.use(VueClipBoard);

Vue.config.productionTip = false;
const config = { 
  number: 'BigNumber',
};

const math = create(all, config);
Vue.prototype.$math = math;


if (util.isWeixinBrowser()) {
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  };
}
new Vue({
  router,
  store,
  mixins: [userLogin, jumpPc, setChannelCode],
  render: (h) => {
    util.zhichiCustom();
    util.inputBlur();
    if (util.isWeixinBrowser() && process.env.NODE_ENV !== 'production') {
      new VConsole();
    }
    return h(App);
  },
}).$mount('#app');
