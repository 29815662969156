import commonApi from '@/api/common/index';
export default {
  beforeCreate () {
    this.$bridge.registerhandler('userLogin', (data) => {
      // data是原生app传递给h5的参数
      console.log('userId==>', data);
      if(data) {
        commonApi.loginByTempCode(data).then(res=> {
          this.$store.commit('user/setUserInfo', res.data.data);
        });
      } else {
        this.$store.commit('user/clearUserInfo');
      }
      this.$store.commit('common/setSourceApp', true);
    });
  }
};