<template>
  <div v-if="quickData.length" class="quick-select">
    <swiper ref="mySwiper" class="qs-content" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in quickData" :key="index" class="swiper-slide">
        <div class="qs-item" @click="toPath(item)">
          <div class="qs-icon"><img :src="item.icon" /></div>
          <span class="qs-title">{{ item.title }}</span>
        </div>
      </swiper-slide>
    </swiper>
    <!-- <div class="qs-content">
      <div v-for="item in quickData" :key="item.id" class="qs-item" @click="toPath(item)">
        <div class="qs-icon"><img :src="item.icon" /></div>
        <span class="qs-title">{{ item.title }}</span>
      </div>
    </div> -->
  </div>
</template>
<script>
/**
 * 注意：
 * 修改这个文件需要注意 home-guide（引导页）共用此组件显示!!!
 * 修改这个文件需要注意 home-guide（引导页）共用此组件显示!!!
 * 修改这个文件需要注意 home-guide（引导页）共用此组件显示!!!
 * */
import util from '@/assets/js/util';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'QuickSection',
  props: {
    quickData: {
      type: Array,
      default: () =>[]
    },
    disabledPath: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      swiperOptions: {
        freeMode: {
          enabled: true,
        },
        sticky: true,
        slidesPerView: 4.5,
      }
    };
  },
  computed: {
    ...mapGetters('user', ['isLogin']),
  },
  methods: {
    ...mapActions({
      'wxLogin': 'common/wxLogin'
    }),
    // 路由跳转
    toPath ({ path, needLogin, customClick }) {
      if(this.disabledPath) { return; }
      if (needLogin && !this.isLogin) {
        if (util.isWeixinBrowser()) {
          return this.wxLogin({ route: this.$route, snsapiType: true });
        }
        return this.$router.push('/uc/login');
      }
      if(customClick) {
        return customClick();
      }
      this.$router.push(path);
    },
  }
};
</script>
<!--
 * 注意：
 * 修改这个文件需要注意 home-guide（引导页）共用此组件显示!!!
 * 修改这个文件需要注意 home-guide（引导页）共用此组件显示!!!
 * 修改这个文件需要注意 home-guide（引导页）共用此组件显示!!!
 *-->
<style lang="less" scoped>
.quick-select {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.28rem;
  .qs-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.40rem;
  }
  .qs-item {
    font-size: 0.24rem;
    text-align: center;
    line-height: 0.35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0 0.42rem;
  }
  .qs-icon {
    width: 0.9rem;
    height: 0.9rem;
  }
  .qs-icon img {
    width: 100%;
    height: 100%;
  }
}
</style>