<template>
  <div v-show="showHeader" v-cloak ref="header" class="page-header-box" :style="{top: top + 'rem'}">
    <div class="header-left">
      <img class="logo" src="@/assets/img/header_logo.png" alt="" @click="toPath('/')">
    </div>
    <div class="header-right">
      <div class="customer-box" @click="handleClickCustomer">
        <img src="@/assets/img/customer.png" alt="" class="customer-img">
        <!-- <p class="custome-title">客服</p> -->
      </div>
      <p v-if="!isLogin" class="login-btn" @click="toPath('/uc/index')">登录</p>
      <div v-else class="avatar-box" @click="toPath('/uc/index')">
        <img v-if="userInfo.avatar" class="avatar" :src="getAvatar()" alt="" @error="userAvatarErr">
        <img v-else class="avatar" :src="getDefaultAvatar" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  name: 'common-header',
  props: {
    top: {
      type: [String, Number],
      default: 0
    },
  },
  data () {
    return {
    };
  },
  mounted () {
    this.$nextTick(() => {
      zc('config', {
        'card_title': '',
        'card_url': '',
        'card_desc': '',
        'card_note': '',
        'card_picture': ''
      });
    });
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('common', ['showHeader']),
    ...mapGetters('user', ['isLogin', 'getAvatar', 'getDefaultAvatar'])
  },
  created () {
    this.$nextTick(() => {
      this.setHeaderHeight(this.$refs.header.offsetHeight);
    });
  },
  methods: {
    ...mapMutations({
      setHeaderHeight: 'common/setHeaderHeight' 
    }),
    handleClickCustomer () {
      document.querySelector('#zhiCustomBtn').click();
    },
    userAvatarErr (e) {
      var img = e.srcElement;
      img.src = this.getDefaultAvatar;
    },
    toPath (path, login = '') {
      if (login) {
        var url = location.href;
        path += `?url=${encodeURIComponent(url)}`;
      }
      this.$router.push({
        path
      });
    }
  }, 
};
</script>
<style lang="less" scoped>
  [v-cloak] {
    display: none;
  }
  .page-header-box{
    position: sticky;
    left: 0;
    padding: .2rem .32rem;
    height: .48rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    z-index: 999;
  }
  .header-left {
    display: flex;
    align-items: center;
    .logo {
      width: 1.66rem;
      height: 0.38rem;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .customer-box {
      margin-right: 0.31rem;
      .customer-img {
        margin-bottom: 0.04rem;
        display: block;
        width: 0.58rem;
        height: 0.7rem;
      }
      .custome-title {
        font-size: 0.2rem;
        font-weight: 400;
        color: #56A7FF;
        text-align: center;
        line-height: 0.29rem;
      }
    }
    .login-btn {
      padding: .14rem .3rem;
      font-size: 0.24rem;
      font-weight: 500;
      color: #027AFF;
      // line-height: 0.35rem;
      background: #EEF6FF;
      border: 1px solid #027AFF;
      border-radius: 0.32rem;
    }
    .avatar {
      display: block;
      width: .7rem;
      height: .7rem;
      border-radius: 50%;
    }
  }
</style>