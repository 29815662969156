const wechatPublicAccountQrCode = [
  {
    path: '/teacher-code/:projectId',
    name: 'teacherCode',
    component: () => import('@/views/teacher-code/index.vue'),
    meta: {
      title: '专属老师备考指导',
    },
  },
];

export default wechatPublicAccountQrCode;
