<template>
  <van-popup :value="show" position="bottom" :close-on-click-overlay="false">
    <div class="bind-phone">
      <div class="bind-phone-wrap">
        <div class="bind-phone-header">
          <span>手机号绑定</span>
          <img v-if="showClose" src="@/assets/img/close.png" class="bh-close" @click="handleClose">
        </div>
        <div class="bind-phone-content">
          <van-form validate-first :show-error="false" @submit="handleClick">
            <div class="form-item">
              <van-field
                v-model="form.phone"
                name="pattern"
                placeholder="请输入手机号"
                autocomplete="off"
                type="digit"
                maxlength="11"
                :rules="rules.phone"
              >
                <template #button>
                  <img v-show="form.phone" class="input-icon" src="@/assets/img/clear.png" style="width: 50%;" @click="handleClearIcon" />
                </template>
              </van-field>
            </div>
            <div class="form-item">
              <van-field
                v-model="form.verificationCode"
                name="pattern"
                autocomplete="off"
                type="digit"
                maxlength="6"
                placeholder="请输入验证码"
              >
                <template #button>
                  <span class="verification-code-btn" :class="[ codeBtnState ? '': 'disable' ]" @click="getCode">{{codeBtnText}}</span>
                </template>
              </van-field>
            </div>
            <div class="form-btn">
              <van-button class="btn" :loading="loginLoading" :class="[loginBtnType ? '' : 'disable']" :disabled="!loginBtnType" block type="info" native-type="submit">一键绑定</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import api from '@/api/common/index';
import loginApi from '@/api/login/index';
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'BindPhone',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timer: null, // 验证码定时器
      timerNum: 60,
      loginLoading: false,
      codeBtnText: '获取验证码',
      rules: {
        phone: [
          { required: true, message: '手机号码不能为空' },
          { pattern: /^((\+|00)86)?1[3-9]\d{9}$/, message: '手机号码格式不正确' },
        ],
        code: [
          { required: true, message: '验证码不能为空' },
          { pattern: /[0-9]{6}/, message: '验证码格式不正确' },
        ]
      },
      form: {
        phone: '', // 手机号
        verificationCode: '', // 验证码
      }
    };
  },
  computed: {
    ...mapState('user', ['sessionKey', 'sessionKeyTime']),
    // 获取验证码按钮是否可以点击
    codeBtnState () {
      var reg = /^((\+|00)86)?1[3-9]\d{9}$/;
      var res = reg.test(this.form.phone);
      if (!res) {
        return false;
      }
      if (this.timer) {
        return false;
      }
      return true;
    },
    // 登录按钮是否可用
    loginBtnType () {
      var phoneReg = /^((\+|00)86)?1[3-9]\d{9}$/;
      var codeReg = /[0-9]{6}/;
      var phoneRes = phoneReg.test(this.form.phone);
      var codeRes = codeReg.test(this.form.verificationCode);
      return (phoneRes && this.form.verificationCode);
    }
  },
  created () {
  },
  methods: {
    ...mapMutations({
      setUserInfo: 'user/setUserInfo',
      removeSessionKey: 'user/removeSessionKey'
    }),
    // 清除输入框
    handleClearIcon () {
      this.form.phone = '';
    },
    // 验证码按钮
    getCode () {
      if (!this.codeBtnState) {
        return;
      }
      this.codeBtnText = `${this.timerNum}S后重新获取`;
      this.sendMobileCode();
      this.timer = setInterval(() => {
        this.timerNum --;
        this.codeBtnText = `${this.timerNum}S后重新获取`;
        if (this.timerNum < 0) {
          this.timerNum = 60;
          this.codeBtnText = '重新获取';
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);
    },
    // 表单验证
    handleClick () {
      console.log('通过校验');
      let time = Date.parse(new Date());
      if (time - (this.sessionKeyTime + 240000) > 0) {
        this.$toast('授权已过期,请重新授权');
        this.removeSessionKey();
        return this.clearWxState();
      }

      this.loginLoading = true;
      loginApi.bindMobileByWxCode({
        code: this.form.verificationCode,
        mobile: this.form.phone,
        requestType: 'BINDPHONE',
        sessionKey: this.sessionKey
      }).then(res=> {
        const { code, data } = res.data;
        if( code === 200) {
          this.$toast('绑定成功');
          this.setUserInfo(res.data.data);
          this.$emit('bindsucc', data);
          this.handleClose();
        } else {
          this.$toast('绑定失败，请联系客服人员');
          // this.clearWxState();
        }
      }).catch(err => {
        // this.clearWxState();
      }).finally(() => {
        // this.removeSessionKey();
        this.loginLoading = false;
      });
    },
    // 获取短信验证码
    sendMobileCode () {
      api.sendMobileCode({
        mobile: this.form.phone,
        requestType: process.env.VUE_APP_BINDPHONE
      }).then(res => {
        console.log(res);
        this.$toast(res.data.data);
      }).catch(err => {
        clearInterval(this.timer);
        this.timer = null;
        this.timerNum = 60;
        this.codeBtnText = '获取验证码';
      });
    },
    handleClose () {
      // try {
      //   const pageSourcePath = window.localStorage.getItem('pageSourcePath');
      //   console.log('handleClose', pageSourcePath);
      //   this.$router.replace(pageSourcePath);
      // } catch (error) {
      //   this.$toast(error);
      // }
      this.$emit('update:show', false);
    },
    clearWxState () {
      this.$emit('update:show', false);
      this.$emit('bindPhoneErr');
      let query = this.$route.query;
      let newQuery = {};
      for (let key in query) {
        if (key !== 'state' && query[key] !== 'wxState') {
          newQuery[key] = query[key];
        }
      }
      this.$router.replace({
        query: newQuery
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/form.less";
.bind-phone {
  // background: fixed;
  // width: 100vw;
  // height: 100vh;
  // left: 0;
  // top: 0;
  // background: rgba(0, 0, 0, 0.3) url('@/assets/img/bindphone_bg.png') no-repeat;
  // background-size: 100%;
  .bind-phone-wrap {
    width: 100%;
    height: 50%;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .bind-phone-header {
    position: relative;
    padding: 0 0.4rem;
    font-size: 0.32rem;
    line-height: 0.6rem;
    padding: 0.4rem 0;
    text-align: center;
    font-weight: bold;
    .bh-close {
      position: absolute;
      width: 0.45rem;
      height: 0.45rem;
      right: 0.3rem;
    }
  }
  .bind-phone-content {
    padding: 0 0.6rem;
    padding-bottom: 0.6rem;
  }
  .login-text {
    margin-top: .32rem;
    font-size: .2rem;
    font-weight: 400;
    color: #4D5E80;
    line-height: .36rem;
  }
  .check-login {
    margin-top: .48rem;
    font-size: .28rem;
    line-height: .4rem;
    text-align: center;
    font-weight: 400;
    color: #4D5E80;
  }
  .form-item {
    border-bottom: none;
    /deep/ .van-field__body {
      padding: 0 0.3rem;
      background: #F5F5F5;
      line-height: 1rem;
      height: 1rem;
    }
  }
}

</style>
