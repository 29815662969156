export default {
  // H5调用Native
  callhandler (name, data, callback) {
    // // iOS的方法
    // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // }
    // // Android方法
    // if (/(Android)/i.test(navigator.userAgent)) {
    // }
  },
  // Native调用H5
  registerhandler (name, callback) {
    window[name] = callback || function () {};
  }
};