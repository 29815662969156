<template>
  <div v-if="guideShow" class="home-guide">
    <guideMask :show.sync="guideShow" guideKey="homeGuideShow" domClass="page-header-box" :bottom="isPad ? '.15rem' : ''">
      <template slot="guide">
        <div v-if="step === 1" class="guide-step one">
          <div class="guide guide-active">
            <div class="guide-bg"><img class="logo" src="@/assets/img/header_logo.png"></div>
          </div>
          <img class="guide-img active" src="@/assets/img/home/guide_img01.png">
        </div>
        <div v-if="step === 2" class="guide-step two">
          <div class="guide guide-active">
            <div class="guide-bg">
              <img v-if="userInfo.avatar" class="avatar" :src="getAvatar()" alt="" @error="userAvatarErr">
              <img v-else class="avatar" :src="getDefaultAvatar" alt="">
            </div>
          </div>
          <img class="guide-img active" src="@/assets/img/home/guide_img02.png">
        </div>
        <div v-if="step === 3" class="guide-step three">
          <div class="guide guide-active">
            <div class="guide-bg">
              <QuickSection :quick-data="qsItem" :disabled-path="true"></QuickSection>
            </div>
          </div>
          <img v-if="!isPad" class="guide-img active" src="@/assets/img/home/guide_img03.png">
          <img v-if="isPad" class="guide-img active pad" src="@/assets/img/home/guide_img03_active.png">
        </div>
      </template>
      <div v-if="step < 3" class="btn" @click="onStepClick">下一步</div>
    </guideMask>
  </div>
</template>

<script>
import util from '@/assets/js/util';
import guideMask from '@/components/guide-mask';
import QuickSection from '../quick-section';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HomeGuide',
  components: {
    guideMask,
    QuickSection
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('user', ['isLogin', 'getAvatar', 'getDefaultAvatar'])
  },
  props: {
    qsItem: {
      require: true,
      type: Array
    }
  },
  data () {
    return {
      isPad: false,
      guideShow: false,
      step: 1
    };
  },
  watch: {
    guideShow () {
      if(!this.guideShow) {
        this.$emit('hideGuide');
      }
    }
  },
  created () {
    this.isPad = util.isiPad();
    if(window.localStorage.getItem('homeGuideShow')) {
      this.$emit('hideGuide');
    } else {
      this.guideShow = true;
    }
  },
  methods: {
    userAvatarErr (e) {
      var img = e.srcElement;
      img.src = this.getDefaultAvatar;
    },
    onStepClick () {
      this.step++;
    }
  }
};
</script>

<style lang="less" scoped>
  .home-guide {
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .guide-step {
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    .guide-step.one {
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 0.94rem;
      width: 5.04rem;
      height: 2.59rem;
      padding-left: 0.06rem;
      .guide {
        display: flex;
        &.guide-active {
          padding: 0.1rem;
          border-radius: 0.15rem;
          border: 1px dashed #FFFFFF;
          z-index: 4001;
          .guide-bg  {
            display: flex;
            padding: 0.1rem;
            background: #fff;
            border-radius: 0.15rem;
            .logo {
              width: 1.66rem;
              height: 0.38rem;
            }
          }
        }
      }
      .guide-img {
        position: absolute;
        right: 0;
        top: 1.90rem;
        width: 4.23rem;
        height: 1.63rem;
        z-index: 4001;
        &.active {
          display: block;
        }
      }
    }
    .guide-step.two {
      top: 0;
      right: 0.2rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 0.9rem;
      width: 5.82rem;
      height: 2.83rem;
      padding-right: 0.1rem;
      .guide {
        display: flex;
        &.guide-active {
          padding: 0.1rem;
          border-radius: 50%;
          border: 1px dashed #FFFFFF;
          z-index: 4001;
          .guide-bg  {
            display: flex;
            padding: 0.1rem;
            background: #fff;
            border-radius: 50%;
            .avatar {
              display: block;
              width: .7rem;
              height: .7rem;
              border-radius: 50%;
            }
          }
        }
      }
      .guide-img {
        position: absolute;
        right: 0rem;
        top: 2.1rem;
        width: 5.55rem;
        height: 1.61rem;
        z-index: 4001;
        &.active {
          display: block;
        }
      }
    }
    .guide-step.three {
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 4.92rem 0.1rem 0;
      box-sizing: border-box;
      width: 100%;
      height: 2.83rem;
      .guide {
        display: flex;
        justify-content: center;
        &.guide-active {
          padding: 0.1rem;
          width: calc(100% - 0.2rem);
          margin: 0 auto;
          box-sizing: border-box;
          border-radius: 0.15rem;
          border: 1px dashed #FFFFFF;
          z-index: 4001;
          .guide-bg  {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            padding: 0.1rem;
            background: #fff;
            border-radius: 0.15rem;
            ::v-deep .qs-content {
              margin: 0.1rem 0;
            }
          }
        }
      }
      .guide-img {
        position: absolute;
        right: 0.72rem;
        top: 7.25rem;
        width: 6.05rem;
        height: 1.61rem;
        z-index: 4001;
        &.active {
          display: block;
        }
        &.pad {
          top: 60%;
        }
      }
    }
    .btn {
      position: absolute;
      left: 35%;
      bottom: 3rem;
      width: 2.56rem;
      height: 0.86rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height:  0.86rem;
      text-align: center;
      border-radius: 0.15rem 0.15rem 0.15rem 0.15rem;
      border: 0.04rem solid #FFFFFF;
    }
  }
</style>