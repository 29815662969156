<template>
  <div class="course-item" @click="hadnleClickCourse(item)">
    <p class="course-type" :class="[`type-${item.type}`]">{{item.typeFormat}}</p>
    <p class="title">{{ item.name }}</p>
    <div class="content-box">
      <p class="project-name">{{ item.projectName }}</p>
      <div v-if="item.totalHour" class="separation" />
      <p v-if="item.totalHour" class="course-time">{{ `${item.totalHour}课时` }}</p>
      <p v-if="item.tag" class="course-tag">{{ item.tag }}</p>
    </div>
    <div class="footer-box">
      <div v-if="!item.teachers.length" class="teacher-img-box">
        <div class="teacher-item">
          <img class="teacher-img" :src="teacherDefaultImg" alt="">
          <p class="teacher-name">天一老师</p>
        </div>
      </div>
      <div v-else class="teacher-img-box">
        <template v-for="(childItem, childIndex) in item.teachers">
          <div v-if="childIndex < 3" :key="childIndex" class="teacher-item">
            <!-- <img class="teacher-img" :src="childItem.avatar" alt="" @error="avatarError"> -->
            <img class="teacher-img" :src="teacherDefaultImg" alt="">
            <p class="teacher-name">{{ childItem.name }}</p>
          </div>
        </template>
      </div>
      <div v-if="showPrice" class="price-box" :class="{'misans-medium': item.type !== 'FREE'}">
        <p v-if="item.type !== 'FREE'" class="unit">￥</p>
        <p class="price">{{ item.type === 'FREE' ? '免费' : item.minPrice }}</p>
        <!-- <p v-if="item.type === 'PACK'" class="unit">起</p> -->
        <!-- <p v-if="item.type === 'PACK'" class="price"> - </p> -->
        <p v-if="item.type === 'PACK'" class="price">{{item.minPrice != item.maxPrice ? ` - ${item.maxPrice}` : ''}}</p>
      </div>
      <slot name="status" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'course-item',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    showPrice: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      teacherDefaultImg: require('@/assets/img/course-list/teacher_default.png'),
    };
  },
  methods: {
    avatarError (e) {
      let img = e.srcElement;
      img.src = this.teacherDefaultImg;
    },
    hadnleClickCourse (item) {
      this.$emit('hadnleClickCourse', item);
    },
  }
};
</script>

<style lang="less" scoped>
  .course-item {
    position: relative;
    margin-bottom: .24rem;
    padding: .32rem;
    padding-bottom: 0;
    background: #FFFFFF;
    box-shadow: 0rem 0.04rem 0.2rem 0rem rgba(106,152,184,0.1);
    border-radius: 0.08rem;
    .course-type {
      position: absolute;
      top: 0.35rem;
      left: 0.32rem;
      padding: .03rem .06rem;
      font-size: 0.2rem;
      font-weight: 400;
      color: #fff;
      line-height: 0.29rem;
      border-radius: .03rem;
    }
    .title {
      height: .95rem;
      text-indent: 2.7em;
      font-size: .32rem;
      font-weight: 400;
      color: #121236;
      line-height: 0.46rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .content-box {
      margin-top: 0.1rem;
      display: flex;
      align-items: center;
      .project-name {
        max-width: 2.5rem;
        font-size: 0.24rem;
        font-weight: 400;
        color: #9FA0A8;
        line-height: 0.35rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .separation {
        margin: 0 0.1rem;
        // margin-top: 0.09rem;
        width: 0.01rem;
        height: 0.18rem;
        background: #D8D8D8;
      }
      .course-time {
        flex-shrink: 0;
        font-size: 0.24rem;
        font-weight: 400;
        color: #9FA0A8;
        line-height: 0.35rem;
      }
      .course-tag {
        margin-left: 0.2rem;
        max-width: 2.5rem;
        padding: 0 0.06rem;
        font-size: 0.2rem;
        font-weight: 400;
        color: #FF8307;
        line-height: 0.35rem;
        // background: #FFF0E1;
        border-radius: 0.03rem;
        border: 1px solid #FF8307;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .footer-box {
      margin-top: .56rem;
      padding-bottom: 0.32rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .teacher-img-box {
        display: flex;
        align-items: center;
        .teacher-item {
          margin-right: .19rem;
          &:last-child{
            margin-right: 0;
          }
          .teacher-img {
            margin: 0 auto;
            display: block;
            width: .56rem;
            height: .56rem;
            border-radius: 50%;
          }
          .teacher-name {
            margin-top: 0.04rem;
            font-size: 0.22rem;
            font-weight: 400;
            color: #9FA0A8;
            line-height: 0.32rem;
          }
        }
      }
      .price-box {
        display: flex;
        .unit {
          font-size: 0.2rem;
          font-weight: 400;
          color: #EA0000;
          line-height: 0.39rem;
        }
        .price {
          // font-size: 0.36rem;
          font-size: 0.3rem;
          font-weight: 400;
          color: #EA0000;
          line-height: 0.29rem;
          white-space: pre-wrap;
        }
      }
    }
  }
</style>