const activeRouter = [
  {
    path: '/coupon-active',
    name: 'coupon-active',
    component: () => import('@/views/active/coupon-active/index.vue'),
    meta: {
      title: '优惠券活动领取',
      jumpPcPath: ({params, query, data}) => {
        let path = `/coupon-active?activeId=${query.activeId}`;
        return path;
      }
    }
  },
  {
    path: '/coupon-acquire',
    name: 'coupon-acquire',
    component: () => import('@/views/active/coupon-acquire/index.vue'),
    meta: {
      title: '优惠券领取',
      jumpPcPath: ({params, query, data}) => {
        console.log(query);
        let path = `/coupon-acquire?couponId=${query.couponId}`;
        return path;
      }
    }
  },
];
export default activeRouter;