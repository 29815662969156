const courseRouter = [
  {
    path: '/course/list/:type?',
    name: 'course-list',
    component: () => import('@/views/course-list/index.vue'),
    meta: {
      title: '课程列表',
      jumpPcPath: ({params, query, data}) => {
        let typeData = {
          '': 'pro-course',
          'sys': 'pro-course',
          'free': 'free-course'
        };
        let path = `/course/${typeData[params.type]}${`${data.intendedProject.parentId ? `?categoryId=${data.intendedProject.parentId}&projectId=${data.intendedProject.id}` : ''}`}`;
        return path;
      }
    }
  },
  {
    path: '/exp-course',
    name: 'exp-course',
    component: () => import('@/views/exp-course/index.vue'),
    meta: {
      title: '训练营列表',
    }
  },
  {
    path: '/course/detail/:id',
    name: 'course-detail',
    component: () => import('@/views/course-detail/index.vue'),
    meta: {
      title: '课程详情',
      jumpPcPath: ({params, query, data}) => {
        let path = `/course/detail/${params.id}`;
        return path;
      }
    }
  },
  {
    path: '/exp/course/detail/:id',
    name: 'exp-detail',
    component: () => import('@/views/course-detail/index.vue'),
    meta: {
      title: '训练营详情',
    }
  },
  {
    path: '/information/download',
    name: 'download',
    component: () => import('@/views/information-download/index.vue'),
    meta: {
      title: '资料列表',
      jumpPcPath: ({params, query, data}) => {
        let path = `/course/resource${data.intendedProject.parentId ? `?categoryId=${data.intendedProject.parentId}&projectId=${data.intendedProject.id}` : ''}`;
        return path;
      }
    }
  },
  {
    path: '/course/my/detail/:id',
    name: 'myCourseDetail',
    component: () => import('@/views/course-detail/index.vue'),
    meta: {
      title: '我的课程详情', // 暂未使用
    }
  },
];

export default courseRouter;
