<template>
  <div v-if="adList.id && (adList.imgUrl || (adList.adItemDtoList && adList.adItemDtoList.length))" class="home-banner">
    <img class="bg-img" :src="bgImg" alt="">
    <van-swipe v-if="adList.adItemDtoList && adList.adItemDtoList.length > 1" class="my-swipe" loop indicator-color="white" :autoplay="3000" @change="onChange">
      <van-swipe-item v-for="item in adList.adItemDtoList" :key="item.id" @click="toPath(item)">
        <div v-if="item.type==='IMG'"  :style="`background: url(${item.adContent}) no-repeat center center; background-size: 100% 100%; width: 100%; height: 100%`"></div>
        <div v-else>{{ item.adContent }}</div>
      </van-swipe-item>
    </van-swipe>
    <van-swipe v-else class="my-swipe" :loop="false" :show-indicators="false" @change="onChange">
      <van-swipe-item v-if="adList.adItemDtoList.length" :style="`background: url(${adList.adItemDtoList[0].adContent}) no-repeat center center; background-size: 100% 100%;`" @click="toPath(adList.adItemDtoList[0])"></van-swipe-item>
      <van-swipe-item v-else :style="`background: url(${adList.imgUrl}) no-repeat center center; background-size: 100% 100%;`"></van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: 'HomeBanner',
  props: {
    adList: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      swiperLoading: false,
      bgImg: '',
    };
  },
  watch: {
    adList: {
      handler () {
        if(this.adList.adItemDtoList && this.adList.adItemDtoList.length) {
          this.bgImg = this.adList.adItemDtoList[0].adContent;
        } else {
          this.bgImg = this.adList.imgUrl || '';
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onChange (index) {
      if(this.adList.adItemDtoList && this.adList.adItemDtoList.length) {
        this.bgImg = this.adList.adItemDtoList[index].adContent;
      }
    },
    toPath (item) {
      if(item.linkUrl) {
        sessionStorage.setItem('openPath', encodeURIComponent(location.href));
        window.open(item.linkUrl, item.blank ? '_blank' : '_self');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.home-banner {
  position: relative;
  padding: 0.32rem 0.32rem 0.24rem;
  .my-swipe {
    width: 100%;
    height: 2.8rem;
    border-radius: 0.08rem;
    overflow: hidden;
    .van-swipe-item {
      overflow: hidden;
      word-break: break-all;
    }
  }
  .bg-img {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 75%;
    filter: blur(50px);
    opacity: .3;
  }
}
</style>