import request from '../request';

// 账号密码登录
function loginByPwd (data) {
  return request({
    url: '/usercenter/front/api/login/loginByPwd',
    method: 'POST',
    data: {
      mobile: data.mobile,
      pwd: data.pwd,
    },
  });
}

// 验证码登录
function loginBySms (data) {
  return request({
    url: '/usercenter/front/api/login/loginBySms',
    method: 'POST',
    data: {
      code: data.code,
      mobile: data.mobile,
      requestType: data.requestType,
    },
  });
}

// 忘记密码
function forgetPwd (data) {
  return request({
    url: '/usercenter/front/api/account/forgetPwd',
    method: 'POST',
    data: {
      code: data.code,
      mobile: data.mobile,
      newPwd: data.newPwd,
      requestType: data.requestType,
    },
  });
}

// 修改密码
function updatePwd (data) {
  return request({
    url: '/usercenter/front/api/account/updatePwd',
    method: 'PUT',
    data: {
      code: data.code,
      mobile: data.mobile,
      newPwd: data.newPwd,
      requestType: data.requestType,
    },
  });
}

// 退出登录
function logout () {
  return request({
    url: '/usercenter/front/api/account/logout',
    method: 'POST',
  });
}
// 通过微信code获取信息
function loginByWx (data) {
  return request({
    url: '/usercenter/front/api/login/loginByWx',
    method: 'GET',
    data,
  });
}
// 微信授权绑定手机号
function bindMobileByWxCode (data) {
  return request({
    url: '/usercenter/front/api/login/bindMobileByWxCode',
    method: 'POST',
    data,
  });
}
// 修改绑定手机号
function updateBindMobile (data) {
  return request({
    url: '/usercenter/front/api/account/updateBindMobile',
    method: 'PUT',
    data: {
      mobileCode: data.code, // 短信验证码
      newMobile: data.newMobile, // 新手机号
      oldMobile: data.oldMobile, // 旧手机号
      requestType: data.requestType, // 请求Id
    },
  });
}

// 修改用户信息
function updateBasicInfo (data) {
  return request({
    url: '/usercenter/front/api/account/updateBasicInfo',
    method: 'PUT',
    data: {
      avatar: data.avatar, // 头像地址
      birthday: data.birthday, // 生日，yyyy-MM-dd
      gender: data.gender, // 性别;FEMALE:女 MALE:男 UNKNOWN:保密
      nickName: data.nickName, // 昵称
    },
  });
}
export default {
  loginByPwd,
  loginBySms,
  forgetPwd,
  updatePwd,
  logout,
  updateBindMobile,
  updateBasicInfo,
  bindMobileByWxCode,
  loginByWx,
};
