import userApi from '@/api/user/index';
import manDefaultAvatar from '@/assets/img/man_default.png';
import defaultAvatar from '@/assets/img/default_img.png';
import womanDefaultAvatar from '@/assets/img/woman_default.png';
let sessionKey = sessionStorage.getItem('sessionKey') || '';
let sessionKeyTime = Number(sessionStorage.getItem('sessionKeyTime')) || 0;
let userInfo = localStorage.getItem('userInfo');
let token = localStorage.getItem('user_token') || '';
let startUserInfo = {
  account: '', // 账号
  avatar: '', // 用户头像
  bindMobile: false, // 是否绑定手机号
  mobile: '', // 用户电话
  nickName: '', // 昵称
  userId: '', // 用户id
};
if (userInfo) {
  userInfo = JSON.parse(userInfo);
} else {
  userInfo = Object.assign({}, startUserInfo);
}

const state = {
  token: token,
  userInfo: userInfo,
  sessionKey: sessionKey,
  sessionKeyTime: sessionKeyTime,
  defaultImg: {
    UNKNOWN: manDefaultAvatar,
    MALE: manDefaultAvatar,
    FEMALE: womanDefaultAvatar
  }
};

const getters = {
  // 判断用户是否登录
  isLogin (state) {
    return !!(state.token);
  },
  // 返回图片完整链接
  getAvatar (state, getters) {
    return (link = state.userInfo.avatar) => {
      console.log(link);
      if (!link) {
        return getters.getDefaultAvatar;
      }
      return process.env.VUE_APP_BASEIMGURL + link;
    };
  },
  // 获取默认头像
  getDefaultAvatar (state) {
    if (!state.token) {
      return defaultAvatar;
    } else {
      return state.defaultImg[state.userInfo.gender];
    }
  },
  // 更改用户手机号
  getPhone (state) {
    if (state.userInfo.id && !state.userInfo.mobile) {
      return '请补充手机号';
    }
    var reg = /^((\+|00)86)?1[3-9]\d{9}$/;
    var res = reg.test(state.userInfo.mobile);
    if (res) {
      var phone = `${state.userInfo.mobile}`;
      return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    } else {
      return '手机号码异常,请检查';
    }
  },
};
const mutations = {
  setSessionKey (state, data) {
    let date = Date.parse(new Date());
    sessionStorage.setItem('sessionKey', data);
    sessionStorage.setItem('sessionKeyTime', date);
    state.sessionKeyTime = date;
    state.sessionKey = data;
  },
  removeSessionKey (state) {
    sessionStorage.removeItem('sessionKey');
    sessionStorage.removeItem('sessionKeyTime');
    state.sessionKey = '';
    state.sessionKeyTime = 0;
  },
  // 设置用户信息
  setUserInfo (state, res = null) {
    if (!res) {
      return;
    }
    localStorage.setItem('userInfo', JSON.stringify(res.account));
    localStorage.setItem('user_token', res.token);
    state.userInfo = Object.assign(state.userInfo, res.account);
    state.token = res.token;
    if (!res.bindUserProject) {
      try {
        var intendedProject = JSON.parse(window.localStorage.getItem('intendedProject')) || {};
        if (intendedProject.id) {
          userApi.bindUserProject({projectId: intendedProject.id, projectName: intendedProject.name});
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  // 更改用户信息
  setUserData (state, data){
    state.userInfo = Object.assign(state.userInfo, data);
    localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
  },
  // 移除用户信息
  clearUserInfo (state) {
    state.userInfo = Object.assign({}, startUserInfo);
    state.token = '';
    localStorage.removeItem('userInfo');
    localStorage.removeItem('user_token');
  }
};
const actions = {
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};