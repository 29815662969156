<template>
  <div v-if="show" class="header-download-box">
    <div class="header-download-left">
      <img class="close-img" src="@/assets/img/home/close.png" alt="" @click="handlerClose">
      <img src="@/assets/img/home/logo_ad.png" alt="" class="log-img">
      <div class="logo-content">
        <p class="title">天一网校APP</p>
        <p class="text">轻松学 简单考</p>
      </div>
    </div>
    <div class="app-btn" @click="toDownloadAppPage">立即打开</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'header-download',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations('common', ['toDownloadAppPage']),
    handlerClose () {
      this.$emit('update:show', false);
    }
  }
};
</script>

<style lang="less" scoped>
  .header-download-box {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #4C4C4C;
    z-index: 999;
  }
  .header-download-left {
    margin-left: .32rem;
    display: flex;
    align-items: center;
    .close-img {
      margin-right: .32rem;
      display: block;
      width: .24rem;
      height: .24rem;
    }
    .log-img {
      margin-right: .2rem;
      display: block;
      width: 0.8rem;
      height: 0.8rem;
    }
    .logo-content {
      .title {
        font-size: 0.28rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.41rem;
      }
      .text {
        margin-top: 0.05rem;
        font-size: 0.22rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.32rem;
      }
    }
  }
  .app-btn {
    padding: .26rem .4rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 0.48rem;
    background: #027AFF;
  }
</style>