<template>
  <div v-if="courseList.length" class="module-course-pro">
    <div class="course-content">
      <courseItem v-for="item in courseList" :key="item.id" :item="item" @hadnleClickCourse="toCourseDetail" />
      <div class="more-course">
        <p @click="onClickToLink">更多课程<span>&nbsp;&gt;</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from '@/api/home/index';
import courseItem from '@/components/course-item/index.vue';
export default {
  name: 'HomeCoursePro',
  components: {
    courseItem
  },
  props: {
    fetchCallback: {
      type: Function,
      default: () =>([])
    },
    moreLink: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      proLoading: false,
      courseList: [],
    };
  },
  created () {
    this.fetchProList();
  },
  methods: {
    fetchProList () {
      try {
        this.proLoading = true;
        homeApi.getCourseList({
          categoryId: '',
          projectId: '',
          subjectId: '',
          orderFiled: '',
          page: 1,
          size: 4,
          types: ['SYS', 'PACK']
        }).then(res=> {
          if(res.data.code === 200) {
            const data = res.data.data;
            this.courseList = data.rows || [];
            this.fetchCallback(this.courseList);
          }
        }).finally(() => {
          this.proLoading = false;
        });        
      } catch (error) {
        
      }
    },
    onClickToLink () {
      if(this.moreLink) {
        this.$router.push(this.moreLink);
      }
    },
    toCourseDetail (item) {
      if(item.id) {
        this.$router.push(`/course/detail/${item.id}`);
      }
    }

  }

};
</script>

<style lang="less" scoped>
.module-course-pro {
  position: relative;
  padding-bottom: 0.48rem;
  .course-content {
    // font-size: 14px;
    padding-bottom: 0.12rem;
    .more-course {
      padding-top: 0.12rem;
      font-size: 0.24rem;
      color: #027AFF;
      text-align: center;
    }
  }
}
</style>