<template>
  <div class="home-box">
    <headerDownload :show.sync="showHeaderDownload" :style="{  }" />
    <Header />
    <HomeBanner :ad-list="adList"></HomeBanner>
    <QuickSection :quick-data="qsItem"></QuickSection>
    <!-- home-module -->
    <HomeModules v-for="moduleItem in moduleData"
                 :key="moduleItem.componentsKey"
                 :title="moduleItem.title"
                 :module-type="moduleItem.componentsKey"
                 :more-link="moduleItem.moreLink"
                 :dataLength="moduleItem.dataLength"
                 @setDataLength="(length) => moduleItem.dataLength = length"></HomeModules>
    <!-- teacher -->
    <TeacherList :teacherList="teacherList"></TeacherList>
    <HomeAbout></HomeAbout>
    <DownloadApp></DownloadApp>
    <HomeCopyRight></HomeCopyRight>
    <adDialog ref="adDialog" />
    <HomeGuide :qs-item="qsItem" @hideGuide="onShowAd"></HomeGuide>
    <BindPhone :show.sync="bindPhoneShow" />
  </div>
</template>

<script>
import util from '@/assets/js/util';
import { mapState, mapMutations } from 'vuex';
import Header from '@/components/header/index.vue';
import BindPhone from '@/components/bind-phone/index.vue';
import headerDownload from './components/header-download';
import HomeBanner from './components/home-banner';
import QuickSection from './components/quick-section';
import HomeModules from './components/home-modules';
import TeacherList from './components/teacher-list';
import HomeAbout from './components/home-about';
import DownloadApp from './components/download-app';
import HomeCopyRight from '@/components/page-copyright';
import adDialog from './components/ad-dialog';
import HomeGuide from './components/home-guide';

// 
import homeApi from '@/api/home/index';

export default {
  name: 'home-page',
  components: {
    Header,
    BindPhone,
    headerDownload,
    HomeBanner,
    QuickSection,
    HomeModules,
    TeacherList,
    HomeAbout,
    DownloadApp,
    HomeCopyRight,
    adDialog,
    HomeGuide
  },
  computed: {
    ...mapState('user', ['sessionKey'])
  },
  created () {
    this.getAdList();
    this.featchTeacherList();
    if (util.isWeixinBrowser() && this.$route.query.state === 'wxState' && !this.isLogin && this.sessionKey) {
      this.bindPhoneShow = true;
    }
  },
  data () {
    return {
      showHeaderDownload: true,
      bindPhoneShow: false,
      adCode: 'H5-HOME-ROTATION',
      adList: {},
      qsItem: [
        { id: 'qs01', title: '公开课', path: '/course/list/free', needLogin: false, icon: require('@/assets/img/home/qs_01.png'), },
        { id: 'qs02', title: '系统课', path: '/course/list/sys', needLogin: false, icon: require('@/assets/img/home/qs_02.png'), },
        { id: 'qs03', title: '题库', path: '', needLogin: false, customClick: () => this.toDownloadAppPage(), icon: require('@/assets/img/home/qs_03.png'), },
        { id: 'qs04', title: '课程兑换', path: '/transfer/open-mini-app/active', needLogin: false, icon: require('@/assets/img/home/qs_04.png'), },
        { id: 'qs05', title: '资料', path: '/information/download', needLogin: false, icon: require('@/assets/img/home/qs_05.png'), },
        { id: 'qs06', title: '我的课程', path: '/uc/my-course/proCourse', needLogin: true, icon: require('@/assets/img/home/qs_06.png'), },
        { id: 'qs07', title: '我的订单', path: '/uc/my-order', needLogin: true, icon: require('@/assets/img/home/qs_07.png'), },
      ],
      moduleData: [
        // { title: require('@/assets/img/home/free-course.png'), componentsKey: 'free', moreLink: '/course/list/free', dataLength: 0 },
        { title: '公开课', componentsKey: 'free', moreLink: '/course/list/free', dataLength: 0 },
        { title: '资料', componentsKey: 'resource', moreLink: '/information/download', dataLength: 0 },
        { title: '系统课', componentsKey: 'pro', moreLink: '/course/list/sys', dataLength: 0 }
      ],
      teacherList: [],
    };
  },
  methods: {
    ...mapMutations('common', ['toDownloadAppPage']),
    getAdList () {
      try {
        homeApi.getAdList(this.adCode).then(res => {
          if(res.data.code === 200) {
            const data = res.data.data;
            this.adList = data;
          }
        }).finally(() => {
          this.swiperLoading = false;
        });
      } catch (error) {
        
      }
    },
    featchTeacherList () {
      try {
        homeApi.getTeacherList({ page: 1, size: 6, recommend: true }).then(res => {
          if(res.data.code === 200) {
            const data = res.data.data;
            this.teacherList = data.rows;
          }
        }).finally(() => {
          
        });
      } catch (error) {
        
      }
    },
    onShowAd () {
      this.$nextTick(this.$refs.adDialog.getAdList);
    }
  }
};
</script>

<style lang="less" scoped>
  .home-box {
    min-height: 100vh;
    background: #FAFAFA;
  }
</style>
