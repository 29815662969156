<template>
  <div v-if="show" class="guide-mask">
    <slot name="guide" />
    <slot>
      <div class="btn-box" :style="{bottom: bottom}">
        <div class="btn" @click="handleClick">{{ btnText }}</div>
      </div>
    </slot>
  </div>
</template>

<script>
import util from '@/assets/js/util';
export default {
  name: 'guide-mask',
  props: {
    btnText: {
      type: String,
      default: '知道啦'
    },
    bottom: {
      type: String,
      default: '3rem'
    },
    guideKey: {
      type: String,
      default: ''
    },
    domClass: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      zIndex: 1
    };
  },
  watch: {
    show: {
      handler (newVal) {
        if (newVal) {
          util.stopScroll();
          if (this.domClass) {
            this.$nextTick(() => {
              this.zIndex = document.querySelector(`.${this.domClass}`).style['z-index'];
              document.querySelector(`.${this.domClass}`).style['z-index'] = 1;
            });
          }
        } else {
          if (this.domClass) {
            this.$nextTick(() => {
              document.querySelector(`.${this.domClass}`).style['z-index'] = this.zIndex;
            });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleClick () {
      util.startScroll();
      this.$emit('update:show', false);
      this.$emit('closeGuide');
      if (this.guideKey) {
        window.localStorage.setItem(this.guideKey, true);
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .guide-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    z-index: 2000;
    .btn-box {
      position: absolute;
      left: 0;
      bottom: 18%;
      width: 100%;
      height: 0.86rem;
      display: flex;
      align-self: center;
      justify-content: center;
    }
    .btn {
      width: 2.56rem;
      height: 100%;
      font-size: 0.32rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height:  0.86rem;
      text-align: center;
      border-radius: 0.15rem 0.15rem 0.15rem 0.15rem;
      border: 0.04rem solid #FFFFFF;
      z-index: 9;
      
    }
  }
</style>