function href (url) {
  location.href = url;
};
// 设置cookie
function setCookie (name, value, time) {
  // 设置cookie
  const Days = time;
  const exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
      name + '=' + encodeURIComponent(value) + ';expires=' + exp.toGMTString() + ';path=/';
};
// 获取cookie
function getCookie (name)  {
  let arr = document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)'));
  // eslint-disable-next-line
  if (arr != null) return unescape(arr[2]); 
  return null;
};
// 删除cookie
function delCookie (name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  // eslint-disable-next-line
  if (cval != null) 
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString() + ';path=/';
};
// 智齿客服
function zhichiCustom () {
  (function (w, d, e, x) {
    w[e] = function () {
      w.cbk = w.cbk || [];
      w.cbk.push(arguments);
    };
    x = d.createElement('script');
    x.async = true;
    x.id = 'zhichiScript';
    x.className = 'zhi-custom-btn'; // 该class绑定到自定义按钮上 第一步
    x.src = 'https://api.soboten.com/chat/frame/v6/entrance.js?sysnum=ea952de816c7413b8b191be9824e19d4';
    d.body.appendChild(x);
  })(window, document, 'zc');
  zc('config', {
    'expand_size': 'half',
    custom: true, // 设置自定义生效 第二步
    refresh: true,
    're_render': true,
    channelid: 2,
  });
};
// 禁止手势缩放
function disableScaling () {
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
  });
};
// IOS输入框手动处理点击空白区域失焦事件
function inputBlur () {
  let lock = false;
  let addTouchend = (e) => {
    if (!lock) return;
    let isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (!isIos) return;
    if (e.target.nodeName === 'INPUT') return;
    let inputs = document.querySelectorAll('input');
    for(let i = 0; i < inputs.length; i++) {
      inputs[i].blur();
    }
  };
  document.addEventListener('touchstart', function (e) {
    lock = true;
  }, false);
  document.addEventListener('touchmove', function (e) {
    if (!lock) return;
    lock = false;
  }, false);
  document.addEventListener('touchend', addTouchend, false);
}
// 禁止双击放大
function disableDobleScaling () {
  let time = 0;
  document.addEventListener('touchend', function (e) {
    let nowData = Date.parse(new Date());
    if (nowData - time < 50) {
      e.preventDefault();
    }
    time = nowData;
  });
}
// 获取手机系统类型
function getPhoneSys () {
  var userAgent = window.navigator.userAgent;
  var isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1;
  var isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isAndroid) {
    return 'android';
  }
  if (isIOS) {
    return 'ios';
  }
  return null;
}
// 判断是不是指定浏览器
function getBrowser () {
  var userAgent = window.navigator.userAgent;
  var browserData = {
    UCBrowser: 'uc',
  };
  var browser = '';
  for(let key in browserData) {
    if (userAgent.indexOf(key) > -1) {
      browser = browserData[key];
    }
  }
  return browser;
}

function isWeixinBrowser () {
  let ua = window.navigator.userAgent;
  return /micromessenger/i.test(ua);
}

function isIOS () {
  let ua = window.navigator.userAgent;
  return /\(i[^;]+;( U;)? CPU.+Mac OS X/i.test(ua);
}

function isAndroid () {
  let ua = window.navigator.userAgent;
  return ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
}

function isMobile () {
  let ua = window.navigator.userAgent;
  return /(iPhone|iPod|Android|ios|iOS|WebOS|Windows Phone|Phone|Mobile)/i.test(ua);
}

function isiPad () {
  let ua = window.navigator.userAgent;
  return (/macintosh|mac os x/i.test(ua) && window.screen.height > window.screen.width && !ua.match(/(iPhone\sOS)\s([\d_]+)/)) || ua.match(/(iPad).*OS\s([\d_]+)/);
}

function filterLogin (route = {}) {
  const { name, path } = route;
  const routePath = ['/exp-course', '/transfer'];
  const routeName = ['exp-detail'];
  console.log('path', path);
  console.log('name', name);
  return routePath.includes(path) || routeName.includes(name);

}

// 节点全屏
const fullScreenFn = {
  isFullScreen: function () {
    return !!(
      document.fullscreen ||
      document.mozRequestFullScreen ||
      document.webkitRequestFullscreen ||
      document.msRequestFullscreen ||
      document.fullscreenElement
    );
  },
  launchIntoFullscreen: function (element) {
    // 全屏
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  },
  exitFullscreen: function () {
    // 退出全屏
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  },
  setFullscreen: function (element) {
    const fullscreen = !this.isFullScreen();
    if (fullscreen) {
      this.launchIntoFullscreen(element);
    } else {
      this.exitFullscreen();
    }
  },
};
let touchmove = function (e){e.preventDefault();};
// 禁止页面滑动
function stopScroll () {
  document.body.style.overflow = 'hidden';
  document.addEventListener('touchmove', touchmove, {passive: false});
};
// 开启页面滑动
function startScroll () {
  document.body.style.overflow = '';
  document.removeEventListener('touchmove', touchmove, {passive: false});
};

export default {
  href,
  setCookie,
  getCookie,
  delCookie,
  zhichiCustom,
  disableScaling,
  inputBlur,
  disableDobleScaling,
  getPhoneSys,
  getBrowser,
  isWeixinBrowser,
  isIOS,
  isAndroid,
  isMobile,
  isiPad,
  filterLogin,
  stopScroll,
  startScroll,
  fullScreenFn
};