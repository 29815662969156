import commonApi from '@/api/common/index';
import userApi from '@/api/user/index';
import util from '@/assets/js/util';
import wx from 'weixin-js-sdk';

let cityPromise = null;
let ccPromise = null;
let categoryProjectPromise = null;
let intendedProject = {
  code: '',
  id: '',
  name: '',
  parentId: ''
};
const weixinUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';
try {
  intendedProject = JSON.parse(window.localStorage.getItem('intendedProject')) || intendedProject;
} catch (error) {
  console.log(error);
}
let timer = null;

const state = {
  downloadApp: process.env.VUE_APP_DOWNLOADAPP, // 下载app地址
  URLSchemeAndroid: 'app://www.tywx.com/', // 下载app地址
  URLSchemeIos: 'tianyiwangxiao://', // 下载app地址
  ccWatermark: 'https://images.tianyiwangxiao.com/logo_blue.png', // cc 水印图片
  intendedProject: intendedProject, // 用户选择的项目数据
  headerHeight: 0,
  cityData: [], // 城市数据
  categoryProjectList: [], // 分类、项目数组
  projectList: [], // 用户选择的项目数组
  showHeader: true, // 隐藏顶部导航栏
  ccConfig: { // CC配置项
    apiKey: '',
    liveDomain: '',
    userId: ''
  },
  openId: '',
  sourceApp: false,
  wxConfig: {},
};
const getters = {
};
const mutations = {
  toDownloadAppPage (state) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      util.href(state.downloadApp);      
    }, 0);
    document.addEventListener('visibilitychange', () => {
      clearTimeout(timer);
    });
    if(util.isAndroid()) {
      util.href(state.URLSchemeAndroid);      
    } else if(util.isIOS() || util.isiPad()) {
      util.href(state.URLSchemeIos);      
    }
  },
  setSourceApp (state, data) {
    state.sourceApp = data;
  },
  // 公共头部高度
  setHeaderHeight (state, data) {
    state.headerHeight = data;
  },
  // 设置省市县数据
  setCityData (state, data = null) {
    state.cityData = data;
  },
  // 设置CC配置
  setCCConfig (state, data = null) {
    state.ccConfig = data;
  },
  // 设置OpenId
  setOpenId (state, data = null) {
    state.openId = data;
  },
  // 设置是否隐藏公共头部
  setHiddenHeader (state, data) {
    state.showHeader = data;
  },
  // 设置分类、项目数据
  setCategoryProjectList (state, data) {
    state.categoryProjectList = data;
  },
  // 设置当前项目数据
  setProjectList (state, data) {
    state.projectList = data;
  },
  // 设置当前选择的项目
  setIntendedProject (state, data) {
    if (data.id === state.intendedProject.id) return;
    let token = localStorage.getItem('user_token') || '';
    if (token) {
      userApi.bindUserProject({projectId: data.id, projectName: data.name});
    }
    state.intendedProject = Object.assign(state.intendedProject, data);
    localStorage.setItem('intendedProject', JSON.stringify(state.intendedProject));
  },
  // 设置微信配置
  async setWxConfig (state, data = {}) {
    state.wxConfig = data;
    await wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.nonceStr,
      signature: data.signature,
      jsApiList: [
        'checkJsApi',
        'showMenuItems',
        'hideOptionMenu',
        'updateAppMessageShareData',
        'updateTimelineShareData',
      ]
    });
    wx.error(function (res){
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(res);
    });
  },
};
const actions = {
  getCityData ({ state, commit }){
    if (!cityPromise) {
      cityPromise = new Promise((reslove, reject) => {
        commonApi.cityData().then(res => {
          commit('setCityData', res.data.data);
          reslove();
        });
      });
    }
    return cityPromise.then(() => {
      return state.cityData;
    });
  },
  getCategoryProjectList ({ state, commit }) {
    if (!categoryProjectPromise) {
      categoryProjectPromise = new Promise((reslove, reject) => {
        commonApi.getTreelist(['C', 'P']).then(res => {
          commit('setCategoryProjectList', res.data.data);
          // console.log(state.intendedProject);
          if (state.intendedProject.parentId) {
            for(let i = 0; i < res.data.data.length; i++) {
              if (res.data.data[i].id === state.intendedProject.parentId) {
                commit('setProjectList', res.data.data[i].children);
              }
            }
          }
          reslove();
        });
      });
    }
    return categoryProjectPromise.then(() => {
      return state.categoryProjectList;
    });
  },
  getCCConfig ({ state, commit }) {
    if (!ccPromise) {
      ccPromise = new Promise((reslove, reject) => {
        commonApi.getCCConfig().then(res => {
          commit('setCCConfig', res.data.data);
          reslove();
        });
      });
    }
    return ccPromise.then(() => {
      return state.ccConfig;
    });
  },
  // 获取用户三方OpenId
  getOpenId ({ state, commit }) {
    if (!openid) {
      openid = new Promise((reslove, reject) => {
        commonApi.getOpenId().then(res => {
          commit('setOpenId', res.data.data);
          reslove();
        });
      });
    }
    return openid.then(() => {
      return state.openId;
    });
  },
  wxLogin ({ state, commit }, { route, snsapiType }) {
    return new Promise((reslove, reject) => {
      console.log(route);
      commonApi.getWxConfig({ silent: snsapiType }).then(res=> {
        const { code, data } = res.data;
        if(code === 200) {
          if(route && route.fullPath) {
            window.localStorage.setItem('pageSourcePath', route.fullPath);
          }
          if(snsapiType) {
            window.location.replace(`${weixinUrl}?appId=${data.appId}&redirect_uri=${data.redirectUrl}&response_type=code&scope=${data.scope}&style=${data.style}&forcePopup=false&state=wxBase#wechat_redirect`);
          } else {
            window.location.replace(`${weixinUrl}?appId=${data.appId}&redirect_uri=${data.redirectUrl}&response_type=code&scope=${data.scope}&style=${data.style}&forcePopup=true&state=wxState#wechat_redirect`);
          }
          reslove(res);
        }
        reject(res);
      }).catch(err=> {
        reject(err);
      });
    });
  },
  // 获取公众号JsApi配置
  getWxMpConfig ({ commit }, data = {}) {
    return new Promise((reslove, reject) => {
      commonApi.getMpJsApiConfig(data).then(async res=> {
        const { code, data } = res.data;
        console.log(res);
        if(code === 200) {
          commit('setWxConfig', data);
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: [
                'showMenuItems',
                'hideOptionMenu',
                'updateAppMessageShareData',
                'updateTimelineShareData',
              ],
              success: function (res) {
                console.log('success');
                reslove(wx, res);
              },
              fail: function (err) {
                console.log('fail');
                reject(err);
              }
            });
          });
        }
      }).catch(err=> {
        reject(err);
      });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};