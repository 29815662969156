<template>
  <div v-show="dataLength" class="home-modules">
    <div class="module-header">
      <h3 class="module-title">
        <span>{{ title }}</span>
      </h3>
      <div class="module-more" @click="onClickToLink(moreLink)">
        <template v-if="moduleType !== 'pro'">
          <span class="more-text">更多</span>
          <em class="more-icon"></em>
        </template>
      </div>
    </div>
    <div class="module-wraplist">
      <components :is="modulelist[moduleType]" :fetchCallback="setDataLength" :more-link="moreLink"></components>
    </div>
  </div>
</template>

<script>
import CourseFree from './course-free';
import CoursePro from './course-pro';
import CourseResource from './course-resource';
export default {
  name: 'HomeModules',
  components: {
    CourseFree,
    CoursePro,
    CourseResource
  },
  props: {
    moduleType: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    moreLink: {
      type: String,
      default: ''
    },
    dataLength: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      modulelist: {
        free: 'courseFree',
        pro: 'coursePro',
        resource: 'courseResource'
      }
    };
  },
  methods: {
    setDataLength (data = []) {
      this.$emit('setDataLength', data.length);
    },
    onClickToLink (link) {
      if(link) {
        this.$router.push(link);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.home-modules {
  position: relative;
  padding: 0 0.32rem;
  .module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.24rem;
    .module-title {
      height: 0.49rem;
      display: block;
      font-size: 0.36rem;
      font-weight: 700;
      color: #1D2035;
      line-height: 0.48rem;
      text-wrap: nowrap;
    }
    .module-more {
      font-size: 0.24rem;
      font-weight: 400;
      color: #9FA0A8;
      margin-right: 0.1rem;
      .more-icon {
        width: 0.18rem;
        height: 0.18rem;
        margin-left: 0.05rem;
        display: inline-block;
        // vertical-align: middle;
        background: url('@/assets/img/home/more-icon.png') no-repeat center;
        background-size: 100%;
      }
    }
  }
}
</style>