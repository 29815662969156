<template>
  <div class="page-copyright">
    <p>Copyright© 2003-{{FullYear}} tianyiwangxiao.com</p>
    <p>版权所有All Rights Reserved 天一网校</p>
    <p>全国客服热线：<a class="phone-tel" href="tel:400-659-7013">400-659-7013</a> </p>
  </div>
</template>

<script>
export default {
  name: 'PageCopyRight',
  data () {
    return {
      Date: new Date()
    };
  },
  computed: {
    FullYear () {
      return this.Date.getFullYear();
    }
  }
};
</script>

<style lang="less" scoped>
.page-copyright {
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  width: 100%;
  font-size: 0.2rem;
  color: #4D5E80;
  line-height: 0.3rem;
  text-align: center;
  background: #fff;
  .phone-tel {
    color: #1D2035;
  }
}
</style>