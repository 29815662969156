<template>
  <div class="home-download-app">
    <div class="download-content">
      <img src="@/assets/img/home/logo_ad.png" alt="" class="log-img">
      <div class="logo-content">
        <p class="title">天一网校APP</p>
        <p class="text">随时随地 伴您学习</p>
      </div>
    </div>
    <div class="app-btn" @click="toDownloadAppPage">立即打开</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'HomeDownloadApp',
  methods: {
    ...mapMutations('common', ['toDownloadAppPage']),
  }
};
</script>

<style lang="less" scoped>
.home-download-app {
  width: 100%;
  height: 3.31rem;
  background: url('@/assets/img/home/download-app.png') no-repeat center center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .download-content {
    display: flex;
    margin-bottom: 0.56rem;
   .log-img {
      margin-right: .2rem;
      display: block;
      width: 0.8rem;
      height: 0.8rem;
    }
    .logo-content {
      .title {
        font-size: 0.28rem;
        font-weight: 500;
        color: #FFFFFF;
        background: linear-gradient(296deg, #1057A7 1%, #2773C7 100%);
        line-height: 0.41rem;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .text {
        margin-top: 0.05rem;
        font-size: 0.22rem;
        font-weight: 400;
        color: #165FB0;
        line-height: 0.32rem;
        opacity: 0.6;
      }
    }

  }
  .app-btn {
    width: 4.7rem;
    height: 0.73rem;
    background: linear-gradient(145deg, #7FBAFA 0%, #027AFF 98%);
    box-shadow: 0px 10px 10px 0px rgba(0,119,255,0.1);
    border-radius: 0.1rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 0.73rem;
    text-align: center;
  }
}
</style>
