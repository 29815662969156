const orderRouter = [
  {
    path: '/confirm-order/:id',
    name: 'confirmOrder',
    component: () => import('@/views/confirm-order/index.vue'),
    meta: {
      title: '确认订单',
      jumpPcPath: ({params, query, data}) => {
        let path = `/submit-order/${params.id}`;
        return path;
      }
    }
  },
  {
    path: '/order-detail/:id',
    name: 'orderDetail',
    component: () => import('@/views/order-detail/index.vue'),
    meta: {
      title: '订单详情',
      jumpPcPath: ({params, query, data}) => {
        let path = `/uc/my-order/${params.id}`;
        return path;
      }
    }
  },
];

export default orderRouter;
