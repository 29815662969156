<template>
  <div id="app" class="app" :class="{'pc-width': !isH5}">
    <router-view />
    <div v-show="false" id="zhiCustomBtn" class="zhi-custom-btn">
      <img class="kefu" src="@/assets/img/kefu.gif" alt="">
      <img class="close" src="@/assets/img/kefu_close.png" alt="">
    </div>
  </div>
</template>

<script>
import util from '@/assets/js/util';  
export default {
  name: 'app-index',
  data () {
    return {
      isH5: true
    };
  },
  created () {
    this.isH5 = util.isMobile() || util.isiPad();
  }
};
</script>
<style lang="less" scoped>
  .pc-width {
    margin: 0 auto;
    max-width: 1200px;
  }
  .zhi-custom-btn {
    position: fixed;
    bottom: 150px;
    right: 40px;
    z-index: 999;
  }
  .kefu {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .close {
    position: absolute;
    top: -10px;
    right: -20px;
    width: 15px;
  }
</style>
