import util from '@/assets/js/util';  
export default {
  beforeCreate () {
    const isMobile = util.isMobile() || util.isiPad();
    // const isMobile = false;
    const locationOrigin = window.location.origin;
    const isMobileOrigin = locationOrigin.indexOf('m') >= 0;
    // const isMobileOrigin = true;
    const { ready, pending, current } = this.$router.history;
    if (!isMobile && isMobileOrigin && ((!pending && current.path === '/' && current.name === 'home') || (pending && !ready))) {
      const { meta, params, query } = pending || current;
      const targetOrigin = locationOrigin.replace(/(m)/i, 'www');
      if (meta.jumpPcPath) {
        let data = {
          intendedProject: this.$store.state.common.intendedProject
        };
        let jumpPath = targetOrigin + '/new' + meta.jumpPcPath({params, query, data});
        // console.log(jumpPath);
        window.location.href = jumpPath;
      }
    }
  },
};
