import request from '../request';

// 获取短信验证码
function sendMobileCode (data) {
  return request({
    url: '/usercenter/front/api/sms/sendMobileCode',
    method: 'POST',
    data: {
      mobile: data.mobile,
      // VERIFICATIONCODE:验证码,FORGETPWD:忘记密码,REGISTER:注册,BINDPHONE:绑定手机号
      requestType: data.requestType,
    },
  });
}

// 获取省市县数据
function cityData () {
  return request({
    url: '/sys/front/api/region/getTreeList',
    method: 'POST',
    data: {},
  });
}
// 获取省市县数据
function getTreelist (data) {
  return request({
    url: '/sys/front/api/class/treelist',
    method: 'POST',
    data,
  });
}

// 上传图片
function uploadImg (data) {
  let formData = new FormData();
  formData.append('file', data.file);
  return request({
    setContentTypeHeader: 'multipart/form-data',
    url: '/sys/front/api/material/uploadImage',
    method: 'POST',
    data: formData,
  });
}

// 获取CC配置
function getCCConfig () {
  return request({
    url: '/products/front/api/bokecc/getCCConfig',
    method: 'GET',
  });
};

// 通过临时凭证登录
function loginByTempCode (userId) {
  return request({
    url: `/usercenter/front/api/login/loginByTempCode/${userId}`,
    method: 'POST'
  });
};

// 微信登录配置
function getWxConfig (data) {
  return request({
    url: '/usercenter/front/api/login/wxConfig',
    method: 'GET',
    data
  });
};

// 通过SessionId获取小程序短链接
function getMiniLink (sessionId) {
  return request({
    url: '/usercenter/front/api/wechatauth/getMiniLink/' + sessionId,
    method: 'GET',
  });
};

// 通过跳转标识获取小程序短链接
function postMiniLink (data) {
  return request({
    url: '/usercenter/front/api/wechatauth/getMiniLink',
    method: 'POST',
    data
  });
};

// 获取销售二维码
function getQrcode (data, noSourceType = false,) {
  return request({
    url: '/crm/front/api/sales/getQrcode',
    method: 'POST',
    data: data,
    noSourceType,
  });
};

// 获取指定销售二维码
function getQrcodeById (data) {
  return request({
    url: '/crm/front/api/sales/getQrcodeById',
    method: 'POST',
    data
  });
};

// 获取公众号JsApi配置
function getMpJsApiConfig (data) {
  return request({
    url: '/usercenter/front/api/wechatauth/getMpJsApiConfig',
    method: 'GET',
    data
  });
};


export default {
  sendMobileCode,
  cityData,
  getTreelist,
  uploadImg,
  getCCConfig,
  loginByTempCode,
  getWxConfig,
  getMiniLink,
  postMiniLink,
  getQrcode,
  getQrcodeById,
  getMpJsApiConfig
};
