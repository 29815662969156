const permissionsRouter = [
  {
    path: '/uc/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录',
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/login';
        return path;
      }
    },
  },
  {
    path: '/uc/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/uc/forgot-password/index.vue'),
    meta: {
      title: '忘记密码',
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/forgot-password';
        return path;
      }
    },
  },
  {
    path: '/uc/index',
    name: 'my',
    component: () => import('@/views/uc/my/index.vue'),
    meta: {
      title: '个人中心',
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/account-manage/personal';
        return path;
      }
    },
  },
  {
    path: '/uc/user-account',
    name: 'user-account',
    component: () => import('@/views/uc/user-account/index.vue'),
    meta: {
      title: '我的账户',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/account-manage/security';
        return path;
      }
    },
  },
  {
    path: '/uc/user-address',
    name: 'user-address',
    component: () => import('@/views/uc/user-address/index.vue'),
    meta: {
      title: '收货地址',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/account-manage/personal';
        return path;
      }
    },
  },
  {
    path: '/uc/operation-address',
    name: 'operation-address',
    component: () => import('@/views/uc/operation-address/index.vue'),
    meta: {
      title: '收货地址详情',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/account-manage/personal';
        return path;
      }
    },
  },
  {
    path: '/uc/edit-password',
    name: 'edit-password',
    component: () => import('@/views/uc/forgot-password/index.vue'),
    meta: {
      title: '修改密码',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/account-manage/security';
        return path;
      }
    },
  },
  {
    path: '/uc/user-mobile',
    name: 'user-mobile',
    component: () => import('@/views/uc/user-mobile/index.vue'),
    meta: {
      title: '修改手机号',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/account-manage/security';
        return path;
      }
    },
  },
  {
    path: '/uc/my-course/:type',
    name: 'myCourse',
    component: () => import('@/views/uc/user-course/index.vue'),
    meta: {
      title: '我的课程',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let courseTypeRouter = {
          proCourse: 'sys',
          freeCourse: 'free',
          addCourse: 'add',
        };
        let path = `/uc/my-course/${courseTypeRouter[params.type]}`;
        return path;
      }
    },
  },
  {
    path: '/uc/my-order',
    name: 'myOrder',
    component: () => import('@/views/uc/user-order/index.vue'),
    meta: {
      title: '我的订单',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/my-order';
        return path;
      }
    },
  },
  {
    path: '/uc/my-coupon',
    name: 'my-coupon',
    component: () => import('@/views/uc/coupon/index.vue'),
    meta: {
      title: '我的优惠券',
      permission: true,
      jumpPcPath: ({params, query, data}) => {
        let path = '/uc/my-coupon';
        return path;
      }
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index.vue'),
    meta: {
      title: '关于我们',
      jumpPcPath: ({params, query, data}) => {
        let path = '/about';
        return path;
      }
    },
  },
  {
    // 用户协议
    path: '/uc/user-agreement',
    name: 'userAgreement',
    component: () => import('@/views/uc/user-agreement/index.vue'),
    meta: {
      jumpPcPath: ({params, query, data}) => {
        let path = '/hc/user-agreement';
        return path;
      }
    },
  },
  {
    // 隐私政策
    path: '/uc/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/uc/privacy-policy/index.vue'),
    meta: {
      jumpPcPath: ({params, query, data}) => {
        let path = '/hc/privacy-policy';
        return path;
      }
    },
  }
];

export default permissionsRouter;
